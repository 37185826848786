<template>
  <v-dialog
    v-model="dialogAddAtividade"
    @click:outside="$emit('update:dialogAddAtividade', false)"
    @keydown.esc="$emit('update:dialogAddAtividade', false)"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        Adicionar Nova Atividade
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddAtividade', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!--Titulo -->
            <v-col cols="12">
              <v-text-field
                v-model="atividade.titulo"
                label="Título"
                required
                :rules="[
                  ...formRules,
                  (v) => v.length <= 255 || 'Máximo 255 caractere',
                ]"
                counter="255"
                max="255"
              ></v-text-field>
            </v-col>

            <v-col v-if="!clienteComponent" cols="12" md="6">
              <v-autocomplete
                class="mt-9"
                v-model="selectedCliente"
                :items="clientes"
                :search-input.sync="searchClientes"
                @keyup="buscaCliente"
                dense
                hide-no-data
                item-text="nome_fantasia"
                item-value="id"
                label="Cliente"
                prepend-inner-icon="mdi-magnify"
                return-object
                required
                :rules="formRules"
              ></v-autocomplete>
            </v-col>

            <!-- data inicio-->
            <v-col class="mt-8" cols="12" :md="clienteComponent ? '6' : '3'">
              <DataField
                label="Data Início"
                :data_sync.sync="atividade.inicio"
                obrigatorio
                :data_min="today"
                :data_max="atividade.entrega"
              />
            </v-col>

            <!--data entrega -->
            <v-col class="mt-8" cols="12" :md="clienteComponent ? '6' : '3'">
              <DataField
                label="Data Entrega"
                :data_sync.sync="atividade.entrega"
                obrigatorio
                :data_min="atividade.inicio ? atividade.inicio : today"
              />
            </v-col>

            <!--Servicos prestados -->
            <v-col cols="12" md="4">
              <v-autocomplete
                class="mt-9"
                v-model="selectedServico"
                :items="cliente_servicos"
                dense
                hide-no-data
                item-text="servico"
                item-value="servico_id"
                label="Serviço(s) a ser(em) executado(s)"
                prepend-inner-icon="mdi-magnify"
                return-object
                required
                :rules="formRules"
              ></v-autocomplete>
            </v-col>

            <!--Prioridade da atividade -->
            <v-col cols="12" md="2">
              <v-select
                v-model="selectedPrioridade"
                :items="prioridades"
                label="Prioridade"
                item-text="descricao"
                item-value="id"
                dense
                class="pt-8"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-icon left :color="item.cor"> mdi-flag </v-icon>
                  {{ item.descricao }}
                </template>
                <template v-slot:item="{ attrs, item }">
                  <v-icon left :color="item.cor"> mdi-flag </v-icon>
                  {{ item.descricao }}
                </template>
              </v-select>
            </v-col>

            <!-- Colaboradores Atividade -->
            <v-col cols="12" md="6">
              <v-autocomplete
                class="mt-9"
                v-model="selectedColaboradores"
                :items="colaboradores"
                dense
                chips
                deletable-chips
                :error="colaboradorRequired"
                :error-messages="colaboradorRequired ? 'Obrigatorio' : ''"
                small-chips
                hide-no-data
                item-text="nome"
                item-value="id"
                label="Colaboradores"
                prepend-inner-icon="mdi-magnify"
                return-object
                required
                :disabled="
                  cliente_id ? false : this.selectedCliente && this.selectedCliente.id ? false : true
                "
                :rules="formRules"
                multiple
              ></v-autocomplete>
            </v-col>

            <!--Explicao do servico -->
            <v-col cols="12" md="6">
              <label>Descrição dos serviços</label>
              <v-textarea
                v-model="atividade.descricao_servicos"
                placeholder="Insira aqui a descrição do serviço..."
                auto-grow
                :rows="4"
                required
                :rules="formRules"
              ></v-textarea>
            </v-col>

            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              cols="12"
              md="auto"
              class="d-flex align-center justify-center pa-0 pb-5"
            >
              <v-divider vertical></v-divider>
            </v-col>

            <v-col cols="12" md="6">
              <label>Informações pertinentes</label>
              <v-textarea
                style="width: 100%"
                v-model="atividade.pertinentes"
                placeholder="Insira aqui as informações pertinentes..."
                auto-grow
                :rows="4"
              ></v-textarea>
            </v-col>

            <!-- Solicitação Midias -->
            <v-col cols="12" v-if="solicitacao && solicitacao.midias.length">
              <h4>Solicitação Mídias</h4>
              <v-chip
                v-for="(midia, index) in solicitacao.midias"
                :key="index"
                close
                small
                label
                @click:close="removeSolicitacaoMidia(index)"
              >
                {{ midia.nome }}
              </v-chip>
            </v-col>

            <v-col cols="12">
              <v-file-input
                suffix="Max 100MB"
                messages="Formatos permetidos : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt."
                v-model="atividade.files"
                placeholder="Anexos"
                accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
                multiple
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    close
                    close-icon="mdi-delete"
                    color="primary"
                    @click:close="remove(text)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn class="mr-3" @click="$emit('update:dialogAddAtividade', false)">
          cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="ml-3"
          @click="createAtividadeCliente"
          :disabled="!validForm"
        >
          adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fetchClientes,
  fetchClienteColaboradores,
} from "@/api/gestor/clientes.js";

import { inputRequired } from "@/helpers/utils.js";
import { postAtividadeCliente } from "@/api/gestor/atividades_clientes.js";
import { fetchClienteServicos } from "@/api/gestor/cliente_servicos.js";
import { format } from "date-fns";

export default {
  name: "AtividadesNovoTrabalho",

  props: {
    dialogAddAtividade: {
      type: Boolean,
      required: true,
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: [Number, String],
    },
    solicitacao: {
      type: Object,
      required: false,
    },
  },

  components: {
    DataField: () => import("../../../../components/fields/DataField.vue"),
  },

  data() {
    return {
      selectedCliente: null,
      selectedServico: null,
      selectedPrioridade: 2,
      loading: false,
      clientes: [],
      cliente_servicos: [],
      atividade: {
        titulo: "",
        status: 0,
        prioridade: 2,
        pertinentes: "",
      },
      searchClientes: "",
      validForm: true,
      today: format(new Date(), "yyyy-MM-dd"),
      formRules: [inputRequired],
      prioridades: [
        { id: 1, descricao: "Baixa", cor: "prioridade_baixa" },
        { id: 2, descricao: "Normal", cor: "prioridade_normal" },
        { id: 3, descricao: "Urgente", cor: "prioridade_urgente" },
        { id: 4, descricao: "Emergência", cor: "prioridade_emergencia" },
      ],
      selectedColaboradores: [],
      colaboradores: [],
      loadingColaboradores: false,
      colaboradorRequired: false,
    };
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        if (
          this.atividade.cliente_id &&
          this.atividade.cliente_id != this.selectedCliente.id
        ) {
          this.atividade.servico_id = null;
        }
        this.atividade.cliente_id = this.selectedCliente.id;
        this.getClienteServicos();
        this.getClienteColaboradores();
      }
    },
    selectedServico() {
      if (this.selectedServico) {
        this.atividade.servico_id = this.selectedServico.servico_id;
      }
    },
    selectedPrioridade() {
      if (this.selectedPrioridade) {
        this.atividade.prioridade = this.selectedPrioridade;
      }
    },
  },

  methods: {
    getClienteServicos() {
      if (this.clienteComponent) {
        return fetchClienteServicos(this.cliente_id)
          .then((response) => {
            this.cliente_servicos = response;
            this.atividade.cliente_id = this.cliente_id;
          })
          .catch(() => {});
      } else {
        return fetchClienteServicos(this.atividade.cliente_id)
          .then((response) => {
            this.cliente_servicos = response;
          })
          .catch(() => {});
      }
    },
    /*puxa todos os clientes*/
    getClientes() {
      this.loading = true;
      return fetchClientes()
        .then((response) => {
          this.clientes = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /*busca todos os clientes*/
    buscaCliente(event) {
      if (this.searchClientes) {
        if (this.searchClientes.length >= 2 && event.key != "Backspace") {
          this.clientes = [];
          this.getClientes(`?search=${this.searchClientes}`);
        }
      }
    },
    /*Remove o clips do anexo */
    remove(item) {
      this.atividade.files.splice(
        this.atividade.files.findIndex((file) => file.name === item),
        1
      );
    },
    /*Criando um novo atividade do cliente */
    createAtividadeCliente() {
      if (this.selectedColaboradores.length === 0) {
        this.colaboradorRequired = true;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        let block = false;

        if (this.atividade.files) {
          this.atividade.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document" &&
              file.name.substr(-4) != ".doc" &&
              file.name.substr(-5) != ".docx"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }

        if (block === false) {
          this.loading = true;
          const atividade = new FormData();
          atividade.append("titulo", this.atividade.titulo);

          atividade.append("cliente_id", this.atividade.cliente_id);
          atividade.append("servico_id", this.atividade.servico_id);
          atividade.append("status", this.atividade.status);
          atividade.append("inicio", this.atividade.inicio);
          atividade.append("entrega", this.atividade.entrega);
          atividade.append(
            "descricao_servicos",
            this.atividade.descricao_servicos
          );

          atividade.append("pertinentes", this.atividade.pertinentes);

          atividade.append("prioridade", this.atividade.prioridade);
          if (this.atividade.files) {
            for (var i = 0; i < this.atividade.files.length; i++) {
              let file = this.atividade.files[i];

              atividade.append("files[" + i + "]", file);
            }
          }
          let colaboradores_2 = [];
          this.selectedColaboradores.forEach((colaborador) => {
            colaboradores_2.push(colaborador.id);
          });
          atividade.append("colaboradores", colaboradores_2);

          if(this.solicitacao) {
            atividade.append("solicitacao", JSON.stringify(this.solicitacao));
          }

          postAtividadeCliente(atividade)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success("Atividade adicionado com sucesso!");
                this.$emit("update:dialogAddAtividade", false);
                this.$emit("fetch-briefings");
                this.colaboradorRequired = false;
                this.loading = false;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.atividade.files = null;
          this.loading = false;
        }
      }
    },

    /* busca colacoradores do cliente */
    async getClienteColaboradores() {
      this.loadingColaboradores = true;
      try {
        const response = await fetchClienteColaboradores(
          this.cliente_id ? this.cliente_id : this.selectedCliente.id
        );
        this.colaboradores = response;
        this.loadingColaboradores = false;
      } catch (e) {
        console.log(e);
        this.loadingColaboradores = false;
      }
    },

    removeSolicitacaoMidia(index) {
      this.solicitacao.midias.splice(index, 1);
    },
  },
  async mounted() {
    this.loading = true;
    if (this.clienteComponent) {
      await this.getClienteServicos();

      if (this.solicitacao) {
        this.atividade.descricao_servicos = this.solicitacao.solicitacao;
        this.atividade.titulo = "[SOLICITAÇÃO] - ";
        await this.getClienteColaboradores();
      }
    } else {
      await this.getClientes();
    }

    this.loading = false;
  },
};
</script>

<style lang="scss"></style>
